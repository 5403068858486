import * as React from "react"
import '../css/bootstrap.min.css';
import '../css/domforte.css';
import {Container, Nav, Navbar, ThemeProvider, Carousel, CarouselItem} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import IconDefinitions from "../components/icons/IconDefinitions";
import {Helmet} from "react-helmet";
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from "lightgallery/react";
import DomforteHelmet from "./DomforteHelmet";

const IndexPage = ({data}) => {

    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <IconDefinitions/>
            <DomforteHelmet title={"DOMFORTE - nowe osiedle domów jednorodzinnych w Krakowie przy ul. Petrażyckiego"}/>
            <Navbar bg={'light'} variant={'light'} fixed={'top'} expand={'lg'}>

                <Container>
                    <Navbar.Brand href={'#home'}>
                        <StaticImage src={"../images/logo.webp"} placeholder={"none"} width={200} alt={"logo"}
                                     className={'align-text-bottom'}/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="domforte-navbar"/>
                    <Navbar.Collapse id="domforte-navbar">
                        <Nav className={'ms-auto nav-masthead'}>
                            <Nav.Link href={'#lokalizacja'}>Lokalizacja</Nav.Link>
                            <Nav.Link href={'#oinwestycji'}>O inwestycji</Nav.Link>
                            <Nav.Link href={'#domy'}>Lokale</Nav.Link>
                            <Nav.Link href={'#dostepnosc'}>Ceny</Nav.Link>
                            <Nav.Link href={'#kontakt'}>Kontakt</Nav.Link>
                            <Nav.Link href={'tel:606388442'}>
                                <svg className="" width="1em" height="1em">
                                    <use href="#phone"/>
                                </svg>
                                &nbsp;606 388 442
                            </Nav.Link>
                            <Nav.Link href={'/'} className={'mx-1 align-bottom'}>
                                <img src={'/staticimg/pl.svg'} alt={"pL"} width={'20px'} height={'10px'}/>
                            </Nav.Link>
                            <Nav.Link href={'/en'} className={'mx-1'}>
                                <img src={'/staticimg/en.svg'} alt={"en"} width={'20px'} height={'10px'}/>
                            </Nav.Link>
                            <Nav.Link href={'/ua'} className={'mx-1'}>
                                <img src={'/staticimg/ua.svg'} alt={"ua"} width={'20px'} height={'10px'}/>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>


            <div style={{display: "grid", height: "80vh"}}>
                <StaticImage style={{gridArea: "1/1", zIndex: '-1'}}
                             src={"../images/widok5b_wide.jpg"}
                             placeholder="blurred" alt={"hero"}/>
                <div style={{gridArea: "1/1", position: "relative"}}
                     className="container-fluid d-flex flex-column align-items-center text-center">
                    <div className={'my-auto'}>
                        <h1 className={'display-3 text-white fw-bold bg-info bg-opacity-50'}>Kraków, ul.
                            Petrażyckiego</h1>
                        <h2 className={'display-5 text-white fw-bold bg-opacity-50 df-bg-green'}>
                            Mieszkaj w mieście wśród zieleni
                        </h2>
                    </div>
                </div>
            </div>


            <div className="container px-4 py-5 retract-top" id="hanging-icons">
                <div className="row">
                    <div className="col-lg-4 my-3">
                        <div className={'mx-4 p-2 bg-light'}>
                            <div className="icon-square-retracted-top">
                                <svg className="bi" width="2em" height="2em">
                                    <use href="#thumbsup"/>
                                </svg>
                            </div>
                            <div className={'p-3'}>
                                <h4>Wygoda i komfort</h4>
                                <p className={'text-justify py-4'}> Wygoda, funkcjonalność i komfort to nasze
                                    priorytety. Każdy z naszych domów posiada
                                    na dwóch poziomach trzy pokoje, salon z kuchnią oraz dwie łazienki.
                                    Na parterze znajduje się duża, otwarta przestrzeń gdzie toczy się wspólne życie
                                    rodzinne. Duże przesuwne drzwi tarasowe w wygodny sposób łączą przestrzeń salonu z
                                    ogródkiem.<br/><br/>

                                </p>
                                <a href="#domy" className="btn btn-primary">
                                    Zobacz plany lokali
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 my-3">
                        <div className={'mx-4 p-2 bg-light'}>
                            <div className="icon-square-retracted-top">
                                <svg className="bi" width="2em" height="2em">
                                    <use href="#quality"/>
                                </svg>
                            </div>
                            <div className={'p-3'}>
                                <h4>Najwyższa jakość</h4>
                                <p className={'text-justify py-4'}>Budynki wyróżniają się nie tylko wygodą i komfortem
                                    ale również jakością użytych
                                    materiałów oraz energooszczędnością. Korzystamy z materiałów
                                    najwyższej jakości. Okna posiadają ciepłe ramy oraz wysokoizolacyjne trzyszybowe
                                    pakiety. Wysoki standard ocieplenia sprawia że domy są ciepłe i spełniają
                                    najnowsze normy.<br/>
                                </p>
                                <a href="#kontakt" className="btn btn-primary">
                                    Dowiedz się więcej
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 my-3">
                        <div className={'mx-4 p-2 bg-light'}>
                            <div className="icon-square-retracted-top">
                                <svg className="bi" width="2em" height="2em">
                                    <use href="#village"/>
                                </svg>
                            </div>
                            <div className={'p-3'}>
                                <h4>Przyjazne otoczenie</h4>
                                <p className={'text-justify py-4'}>Domy są otoczone terenem zielonym oraz nową zabudową
                                    jednorodzinną. W niedalekiej
                                    odległości znajduje się przystanek MPK, paczkomat, market spożywczy, 4 przedszkola,
                                    szkoła oraz stacja Szybkiej Kolei Aglomeracyjnej. Wygodny dojazd do centrum Krakowa,
                                    na obwodnicę, Zakopiankę oraz do Skawiny. </p><br/><br/>
                                <a href="#lokalizacja" className="btn btn-primary">
                                    Przeczytaj o lokalizacji
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <a name={'lokalizacja'}/>
            <div className={'container px-4 py-4 my-5'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'pb-3'}><h1 className={'display-4 fw-bold sectionTitle'}>Ciesz się świeżym
                            powietrzem</h1></div>
                        <div className={"px-4 py-4 text-justify"}>Inwestycja znajduje się w Krakowie przy ulicy
                            Petrażyckiego 83 w
                            obszarze Krakowa znanym jako Sidzina - jest obszar Krakowa wchodzący w skład Dzielnicy VIII
                            Dębniki, dawna podkrakowska
                            wieś przyłączona do miasta Krakowa w 1973r. Sidzina stanowi najbardziej wysuniętą na
                            południe część Dzielnicy VIII, położona jest poza obwodnicą autostradową. Najbliższe
                            sąsiedztwo osiedla stanowi zabudowa mieszkaniowa
                            jednorodzinna oraz tereny niezabudowane. Znajdziesz tu wyjątkowe tereny spacerowe, szkołę,
                            przedszkola, sklepy jak i również świetny dojazd do centrum Krakowa dzięki Szybkiej Kolei
                            Aglomeracyjnej, MPK
                            oraz dobrymi połączeniami drogowymi z Ruczajem, obwodnicą krakowską, Zakopianką oraz ze
                            Skawiną.
                            <br/><br/>
                            <a href={'https://goo.gl/maps/syCSeh2v9r6iHrwx7'}>Zobacz na mapach Google (Kraków, ul.
                                Petrażyckiego 83)</a>
                        </div>
                    </div>
                    <div className={'col-md-6'}>
                        <div className={'card shadow-lg'}>
                            <StaticImage src={"../images/mapa1.png"} placeholder="" alt={"mapa1"}
                                         className={'align-text-bottom'}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'container px-4 py-4'}>
                <div className={'row'}>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#train"/>
                            </svg>
                        </div>
                        <div className={'py-4 px'}>
                            Przystanek PKP i Szybkiej Kolei Administracyjnej w odległości <b>800m</b>.
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#bus"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Przystanek autobusowy MPK (175,243,475) <b>150m</b>
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#school"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Szkoła Podstawowa nr. 133 w Krakowie <b>1100m</b>
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#kindergarten"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Cztery Przedszkola <b>850m - 1100m</b>
                        </div>
                    </div>
                    <div className={'col-md-2 bg-light mx-3 my-5'}>
                        <div className="icon-square-retracted-top">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#supermarket"/>
                            </svg>
                        </div>
                        <div className={'py-4'}>
                            Sklep spożywczy (supermarket Lewiatan) oraz piekarnia <b>500m</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'container px-4 py-4 my-5'}>
                <div className={'row'}>
                    <div className={'col-md-6'}>
                        <div className={'pb-3'}><h1 className={'display-5 fw-bold sectionTitle'}>Wszystko na
                            miejscu</h1></div>
                        <div className={"px-4 py-4 text-justify"}>
                            W okolicy znajdziesz szkołę podstawową - 800m, supermarket spożywczy - 500m, piekarnię -
                            450m, cztery przedszkola w odległości od 850m do 1100m, klub sportowy "Sidzina", dom kultury
                            oraz kościół.
                        </div>
                        <div className={'pb-3'}><h1 className={'display-5 fw-bold sectionTitle'}>Szybko do centrum</h1>
                        </div>
                        <div className={"px-4 py-4 text-justify"}>
                            W odległości 150m znajduje się przystanek MPK dla numerów autobusów 175,243 oraz 475.
                            Dzięki <b>stacji
                            PKP i Szybkiej Kolei Aglomeracyjnej</b> w <b>20 minut</b> dojedziesz do Dworca Centralnego i
                            okolic Rynku Krakowskiego.
                            Bardzo dobre połączenia drogowe - wyjazd na obwodnicę - węzeł Skawina - 4 minuty, węzeł
                            Zakopiańska - 6 minut. Na Ruczaj dojedziesz w 7 minut dzięki Sidzińskiemu przejazdowi nad
                            obwodnicą - ulicą Działowskiego.
                        </div>

                    </div>
                    <div className={'col-md-6'}>
                        <div className={'card shadow-lg'}>
                            <StaticImage src={"../images/mapa2.png"} placeholder="" alt={"mapa2"}
                                         className={'align-text-bottom'}/>
                        </div>
                    </div>
                </div>
            </div>


            <a name={'oinwestycji'}/>
            <div className={'container-fluid px-0 py-4 my-5'} style={{display: "grid"}}>
                <StaticImage style={{gridArea: "1/1"}} layout="fullWidth" src={"../images/salon_wide.jpeg"}
                             placeholder="blurred" alt={""}/>
                <div style={{gridArea: "1/1", position: "relative"}}
                     className="container-fluid d-flex flex-column align-items-center">
                    <div className={'row my-auto w-75 h-100'}>
                        <div className={'col-lg-6 bg-white my-auto p-5'}>
                            <div><h1 className={'display-5 fw-bold sectionTitle text-left ;h'}>Zamieszkaj wygodnie</h1>
                            </div>
                            <div className={'pt-4'}>
                                <p className={'text-justify'}>DOMFORTE Petrażyckiego to kameralne osiedle składające się
                                    z trzech dwulokalowych
                                    domów
                                    jednorodzinnych. Każdy dwupoziomowy segment ma od ok 78m2 do 85m2.
                                    Domy mają przestronne salony połączone z aneksem kuchennym, na piętrze znajdują się
                                    trzy pokoje, każdy dom ma dwie łazienki.
                                </p>
                                <br/>
                                <p className={'text-justify'}>
                                    Segmenty mają swoje niezależne ogródki, czyli wspaniałe miejsca do spędzenia czasu z
                                    rodziną na świeżym powietrzu, do zabawy z dziećmi czy też zorganizowania grilla dla
                                    znajomych i rodziny. Każdy z domów posiada swoje niezależne miejsce parkingowe.
                                </p><br/>
                                <p className={'text-justify'}>
                                    Nowoczesna architektura przy jednoczesnym zachowaniu eleganckiej bryły bydynków oraz
                                    bardzo dużej funkcjonalności pomieszczeń do atuty naszej inwestycji.
                                </p>
                                <br/>
                            </div>
                        </div>
                        <div className={'col-lg-6'}>
                        </div>

                    </div>
                </div>
            </div>
            <div id="inline-gallery-container" className="inline-gallery-container"/>
            <div className={'container px-4 py-4 my-5'}>
                <div className={'row'}>
                    <div className={'col-md-7 text-end'}>
                        <LightGallery plugins={[lgZoom]} mode="lg-fade">
                            <a href={'/staticimg/salon.jpg'}>
                                <img src={'/staticimg/salon_small.jpeg'} className={'mx-1 my-1'}/>
                            </a>
                            <a href={'/staticimg/kuchnia.jpg'}>
                                <img src={'/staticimg/kuchnia_small.jpeg'} className={'mx-1 my-1'}/>
                            </a>
                            <a href={'/staticimg/lazienka.jpg'}>
                                <img src={'/staticimg/lazienka_small.jpeg'} className={'mx-1 my-1'}/>
                            </a>
                            <a href={'/staticimg/widok2b.jpg'}>
                                <img src={'/staticimg/widok2b_small.jpg'} className={'mx-1 my-1'}/>
                            </a>
                            <a href={'/staticimg/widok3b.jpg'}>
                                <img src={'/staticimg/widok3b_small.jpg'} className={'mx-1 my-1'}/>
                            </a>
                            <a href={'/staticimg/widok4b.jpg'}>
                                <img src={'/staticimg/widok4b_small.jpg'} className={'mx-1 my-1'}/>
                            </a>
                            <a href={'/staticimg/widok5b.jpg'}>
                                <img src={'/staticimg/widok5b_small.jpg'} className={'mx-1 my-1'}/>
                            </a>
                            <a href={'/staticimg/widok6b.jpg'}>
                                <img src={'/staticimg/widok6b_small.jpg'} className={'mx-1 my-1'}/>
                            </a>
                            <a href={'/staticimg/widok7b.jpg'}>
                                <img src={'/staticimg/widok7b_small.jpg'} className={'mx-1 my-1'}/>
                            </a>
                            <a href={'/staticimg/widok1a_wide.jpeg'}>
                                <img src={'/staticimg/widok1a_small.jpeg'} className={'mx-1 my-1'}/>
                            </a>
                        </LightGallery>
                    </div>
                    <div className={'col-md-4 my-5 my-md-0'}>
                        <p className={'text-justify'}>
                            Budynki charakteryzują się prostą ale elegancką i nowoczesną architekturą. Duża powierzchnia
                            okien
                            zapewnia doświetlenie i przyjemny nastrój w każdym z pokoi. Olbrzymie, przesuwne okno w
                            salonie pozwala na płynne połączenie salonu z ogrodem w przyjemną, zieloną i bezpieczną
                            przestrzeń życiową dla Ciebie i Twojej rodziny.
                            Zastosowanie materiałów najwyższej jakości w połączniu z wysoką jakością wykonania budynków
                            i montażu jego elementów zapewni wieloletnie bezproblemowe korzystanie.
                        </p>
                        <br/>
                        <p className={'text-justify'}>
                            Domy są bardzo dobrze ocielopne, ogrzewane dwufuncyjnym kotłem gazowym o bardzo nowoczesnej
                            charakterystyce, co pozwola zredukować koszty ogrzewania. Pokoje na piętrze mają pełną
                            wysokość - nie ma w nich skosów.
                            Osiedle DOMFORTE Petrażyckiego będzie doskonałym wyborem dla par i rodzin z dziećmi dając im
                            wysoki komfort i wygodę w otoczeniu pełnym zieleni.</p>
                    </div>
                </div>
            </div>
            <div className={'container px-4 py-4'}>
                <div className={'row'}>
                    <div className={'col-md-3 px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#slonce"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Dużo słońca w Twoim domu</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>Dzięki dużej powierzchni okien, wszystkie pokoje są
                                bardzo dobrze doświetlone naturalnym słonecznym światłem, zapewniając dzięki temu miłą
                                atmosferę i komfortowe warunki do pracy, nauki i zabawy dla całej rodziny.</p>
                        </div>
                    </div>
                    <div className={'col-md-3  px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="3em" height="3em">
                                <use href="#przesuwneokno"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Duże przesuwne okno w salonie</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>W salonie znajduje się olbrzymie okno o szerokości
                                320 cm, które otwiera się przesuwnie tworząc otwór przejściowy o szerokości 160cm, nie
                                posiada progu i pozwala na bardzo wygodne połączenie przestrzeni życiowej salonu z
                                ogródkiem</p>

                        </div>
                    </div>
                    <div className={'col-md-3  px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#pelnawysokosc"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Pełna wysokość bez skosów</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>W naszych domach piętro ma pełną wysokość, dzięki
                                temu cała przestrzeń pokoi nadaje się do efektywnego wykorzystania. Skosy często
                                utrudniają wygodne zagospodarowanie przestrzeni a także ze względu na konieczność
                                wykończenia płytami g-k czesto stają się miejscem pęknięć i uszkodzeń.</p>

                        </div>
                    </div>
                    <div className={'col-md-3  px-5'}>
                        <div className="icon-square">
                            <svg className="bi" width="2em" height="2em">
                                <use href="#ogrzewanie"/>
                            </svg>
                        </div>
                        <h4 className={'pt-3'}>Ekonomiczne ogrzewanie</h4>
                        <div className={'py-4'}>
                            <p className={'text-justify padding-5'}>Domy są bardzo dobrze ocieplone, na całej powierzchni jest ogrzewanie podłogowe, ogrzewane wysokiej jakości
                                dwu-funkcyjnymi kotłami gazowymi z regulatorem pogodowym oraz wentylowane za pomocą rekuperatora - wentylacji mechanicznej z odzyskiem ciepła</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className={'container px-4 py-4 my-5 bg-white'}>
                <Carousel interval={1500}>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/kuchnia_wide.jpeg" alt={"1"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/lazienka_wide.jpeg" alt={"2"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/salon_wide.jpeg" alt={"3"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok2b.jpg" alt={"4"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok3b.jpg" alt={"5"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok4b.jpg" alt={"6"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok5b.jpg" alt={"7"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok6b.jpg" alt={"8"}/>
                    </CarouselItem>
                    <CarouselItem>
                        <StaticImage className="d-block w-100" src="../images/widok7b.jpg" alt={"9"}/>
                    </CarouselItem>
                </Carousel>
            </div>


            <a name={'domy'}/>
            <div className={'container px-4 py-4 my-5 bg-white'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Poznaj swój wymarzony dom</h1></div>

                <p className={"mt-5 text-justify"}>Lokale są sprzedawane jako <b>niezależne lokale mieszkalne</b>.
                    Budynki są budynkami jednorodzinnymi
                    dwulokalowymi z który wyodrębniane są po dwa lokale. Z praktycznego punktu widzenia są to zaś domy -
                    połówki "bliźniaka" - każdy lokal ma swoje niezależne wejście, jest dwupoziomowy oraz dysponuje
                    wyłącznym prawem do dysponowania ogródkiem. W przypadku zakupu lokali przez osoby spoza Unii
                    Europejskiej <b>nie jest wymagane uzyskanie pozwolenia Ministerstwa Spraw Wewnętrznych</b></p>

                <div className={'pt-5'}>
                    <h3>Wariant 1 - 84,65m2</h3>(84,65m2 powierzchni użytkowej + 1,90 m2 powierzchni użytkowej
                    pomocniczej (h&lt;1.9m))

                </div>
                <div className={'row my-5'}>
                    <div className={'col-md-8'}>
                        <StaticImage src={"../images/plan_parter.jpg"} placeholder="" alt={"plan_parter"}
                                     className={'align-text-bottom'}/>

                    </div>
                    <div className={'col-md-4'}>
                        <h4>Parter</h4>41,96m2 powierzchni użytkowej +<br/> 1,90 m2 powierzchni użytkowej
                        pomocniczej
                        (h&lt;1.9m)
                        <div style={{"overflowX": "scroll"}}>
                            <table className={'w-100 niceTable mt-5'}>
                                <thead className={'text-capitalize'}>
                                <tr>
                                    <th>NR</th>
                                    <th>POMIESZCZENIE</th>
                                    <th>POWIERZCHNIA[m2]</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'text-start'}>1</td>
                                    <td className={'text-start'}>WIATROŁAP</td>
                                    <td className={'text-start'}>4,13</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>2</td>
                                    <td className={'text-start'}>ŁAZIENKA</td>
                                    <td className={'text-start'}>3,21</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>3</td>
                                    <td className={'text-start'}>SALON Z ANEKSEM KUCHENNYM</td>
                                    <td className={'text-start'}>34,62</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={'row my-5'}>
                    <div className={'col-md-8'}>
                        <StaticImage src={"../images/plan_pietro.jpg"} placeholder="" alt={"plan_pietro"}
                                     className={'align-text-bottom'}/>

                    </div>
                    <div className={'col-md-4'}>
                        <h4>Piętro</h4>42,69m2 powierzchni użytkowej
                        <div style={{"overflowX": "scroll"}}>
                            <table className={'w-100 niceTable mt-5'}>
                                <thead className={'text-capitalize'}>
                                <tr>
                                    <th>NR</th>
                                    <th>POMIESZCZENIE</th>
                                    <th>POWIERZCHNIA[m2]</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'text-start'}>1</td>
                                    <td className={'text-start'}>HOL</td>
                                    <td className={'text-start'}>3,34</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>2</td>
                                    <td className={'text-start'}>SCHODY</td>
                                    <td className={'text-start'}>2,94</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>3</td>
                                    <td className={'text-start'}>ŁAZIENKA</td>
                                    <td className={'text-start'}>5,84</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>4</td>
                                    <td className={'text-start'}>SYPIALNIA</td>
                                    <td className={'text-start'}>10,15</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>5</td>
                                    <td className={'text-start'}>SYPIALNIA</td>
                                    <td className={'text-start'}>11,53</td>
                                </tr>
                                <tr>
                                    <td className={'text-start'}>6</td>
                                    <td className={'text-start'}>SYPIALNIA</td>
                                    <td className={'text-start'}>8,89</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <h4 className={'my-5'}>Strych</h4>
                        <div>
                            <p>Każdy z domów wyposażony jest w nieocieplony strych o powierzchni podłogi ok 40m2, który
                                może służyć jako przestrzeń magazynowa. Wyłaz na strych - schody opuszczane z sufitu -
                                znajduje się w holu.</p>
                            <p>Oferujemy również wersję z ocieplonym strychem wraz z obiciem skosów i podłogi płytami
                                OSB (opcja dodatkowo płatna)</p>
                        </div>
                    </div>
                </div>

            </div>

            <a name={'dostepnosc'}/>
            <div className={' container px-4 py-4 my-5'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Dostępność</h1></div>
                <div className='py-4 fw-bold'>Planowany termin zakończenia budowy II kwartał 2023r i przekazania - III kwartał 2023r.</div>
                <StaticImage src={"../images/pzt3d.jpeg"} placeholder="" alt={"pzt"}
                             className={'align-text-bottom my-5'}/>
                <div className={'container px-4 w-100 my-5 py-5 py-4 bg-danger bg-opacity-25'}>
                    <h3>
                        UWAGA!<br/>
                    </h3>
                    <b>Wszystkie lokale w tym etapie są sprzedane. Od stycznia 2024 rozpoczynamy sprzedaż dwóch kolejnych lokali w tej lokalizacji, termin zakończenia budowy - IV kwartał 2024. W
                        przypadku zainteresowania prosimy o kontakt: biuro@domforte.pl</b>
                </div>
                <div className={'container px-4 w-100 my-5 py-2'}>
                    <div className="auto-resizable-iframe w-75">
                        <div>
                            <iframe src="https://www.youtube.com/embed/wKkZur2qjcU?si=KSjJVcx8bAcuYBoM" title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                <div className={'container px-4 w-100 my-4 py-4 '} style={{"overflowX": "scroll"}}>
                    <table className={'w-100 text-center niceTable'}>
                        <thead>
                        <tr>
                            <th>OZNACZENIE</th>
                            <th>POWIERZCHNIA</th>
                            <th>OGRÓD</th>
                            <th>DOSTĘPNOŚĆ</th>
                            <th>PLAN</th>
                            <th>CENA</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>A</td>
                            <td>77,27m2</td>
                            <td>153m2</td>
                            {/*<td className={'df-text-green'}>DOSTĘPNY</td>*/}
                            <td>SPRZEDANY</td>
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_a_0.jpg">PARTER</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_a_1.jpg">PIĘTRO</a>
                                </LightGallery></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>B</td>
                            <td>77,27m2</td>
                            <td>204m2</td>
                            <td>SPRZEDANY</td>
                            {/*<td className={'df-text-green'}>DOSTĘPNY</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_b_0.jpg">PARTER</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_b_1.jpg">PIĘTRO</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>C</td>
                            <td>84,65m2</td>
                            <td>198m2</td>
                            {/*<td>REZERWACJA</td>*/}
                            {/*<td className={'df-text-green'}>DOSTĘPNY</td>*/}
                            <td>SPRZEDANY</td>
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_c_0.jpg">PARTER</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_c_1.jpg">PIĘTRO</a>
                                </LightGallery></td>
                            <td>799 000 zł</td>

                        </tr>
                        <tr>
                            <td>D</td>
                            <td>84,65m2</td>
                            <td>86m2</td>
                            <td>SPRZEDANY</td>
                            {/*<td className={'df-text-green'}>DOSTĘPNY</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_d_0.jpg">PARTER</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_d_1.jpg">PIĘTRO</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>E</td>
                            <td>84,65m2</td>
                            <td>82m2</td>
                            <td>SPRZEDANY</td>
                            {/*<td className={'df-text-green'}>DOSTĘPNY</td>*/}
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_e_0.jpg">PARTER</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_e_1.jpg">PIĘTRO</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td>F</td>
                            <td>84,65m2</td>
                            <td>126m2</td>
                            {/*<td className={'df-text-green'}>DOSTĘPNY</td>*/}
                            <td>SPRZEDANY</td>
                            <td>
                                <LightGallery plugins={[lgZoom]} mode="lg-fade">
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_f_0.jpg">PARTER</a>
                                    <a className={'btn btn-green gallery-item'}
                                       data-src="/staticimg/karta_f_1.jpg">PIĘTRO</a>
                                </LightGallery></td>
                            <td></td>

                        </tr>
                        </tbody>
                    </table>

                </div>

                <div className='py-4 text-justify'>W cenie lokalu: ogrodzony ogródek, miejsce parkingowe - wybrukowane, lokal w stanie deweloperskim:
                    instalacje: wodna, kanalizacyjna, gazowa, centralnego ogrzewania (ogrzewanie podłogowe na całej powierzchni), elektryczna (bez gniazdek i kontaktów), tv, internet - łącze
                    światłowodowe. Kocioł centralnego ogrzewania
                    dwufunkcyjny + regulator pogodowy + konsola sterowania wewnętrzna. Wentylacja mechaniczna z rekuperatorem z odzyskiem ciepła. Tynki, wylewki,
                    sufity poddasza - regipsy + ocieplenie (wełna - 30cm), wyłaz na strych (strych bez wykończenia), okna wysokiej jakości o współczynniku 0,76 dla okna referencyjnego, okna w salonie
                    - przesuwne, bezprogowe HST, drzwi wejściowe stalowe, ocieplenie elewacji -
                    styropian grafitowy 16cm, wykończenie elewacji - tynk silikonowy + deska tokato, kanalizacja miejska.
                </div>
            </div>

            <a name={'dziennik'}/>
            <div className={'container px-4'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Dziennik budowy</h1></div>
                <div className={'row my-5 text-center'}>
                    <div className={'col-md-3'}></div>
                    <div className={'col-md-6'}>
                        <LightGallery plugins={[lgZoom]} mode="lg-fade">
                            <a href="/staticimg/dziennik/13.jpg">
                                <img
                                    className="mx-1 my-1"
                                    src="/staticimg/dziennik/13_thumb.jpg"
                                    alt={'2023-04-13'}/>
                            </a>
                            <a href="/staticimg/dziennik/12.jpg">
                                <img
                                    className="mx-1 my-1"
                                    src="/staticimg/dziennik/12_thumb.jpg"
                                    alt={'2022-06-26'}/>
                            </a>
                            <a href="/staticimg/dziennik/11.jpg">
                                <img
                                    className="img-responsive mx-1 my-1"
                                    src="/staticimg/dziennik/11_thumb.jpg"
                                    alt={'2022-06-26'}/>
                            </a>
                            <a href="/staticimg/dziennik/9.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/9_thumb.jpg"
                                     alt={'2022-05-20'}/>
                            </a>
                            <a href="/staticimg/dziennik/7.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/7_thumb.jpg"
                                     alt={'2022-04-02'}/>
                            </a>
                            <a href="/staticimg/dziennik/6.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/6_thumb.jpg"
                                     alt={'2022-03-21'}/>
                            </a>
                            <a href="/staticimg/dziennik/2.jpg">
                                <img className="img-responsive mx-1 my-1"
                                     src="/staticimg/dziennik/2_thumb.jpg"
                                     alt={'2022-03-01'}/>
                            </a>
                        </LightGallery>
                    </div>
                    <div className={'col-md-3'}></div>
                </div>
            </div>
            <a name={'kontakt'}/>
            <div className={'container px-4'}>
                <div><h1 className={'display-4 fw-bold sectionTitle'}>Kontakt</h1></div>
                <div className={'pt-3 text-justify'}>
                    Zapraszamy do kontaktu telefonicznego pod adresem email <b>biuro@domforte.pl</b> oraz pod
                    numerem <b>+48 606-388-442</b> od poniedziałku do piątku w godzinach od 10:00 do 16:00.
                </div>
                <div className='py-4 fw-bold text-justify'>
                    Domy aktualnie są w stanie surowym zamkniętym ze wszystkimi instalacjami, wylewkami i tynkami - można już zobaczyć kształt i rozmiar pomieszczeń,
                    zapraszamy do kontaktu w celu umówienia wizyty na budowie. Planowany termin zakończenia budowy to II
                    kwartał 2023r i przekazania lokali - III kwartał 2023r.
                </div>
                <div className={'row pt-3'}>
                    <div className={'col-md-4'}>
                        <b>DOMFORTE Sp. z o.o.</b><br/>
                        ul. Zamknięta 10/1.5<br/>
                        30-554 Kraków<br/>
                        tel. +48 606-388-442<br/>
                        email: biuro@domforte.pl<br/>
                    </div>


                </div>

            </div>

            <div className="container">
                <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <p>Ulotka i wizualizacje mają charakter poglądowy. Na etapie realizacji mogą nastąpić korekty.
                        Strona nie stanowi oferty w rozumieniu przepisów kodeksu cywilnego.</p>
                    <p className="col-md-4 mb-0 text-muted">&copy; 2022 Domforte Sp. z o.o.</p>
                </footer>
            </div>
        </ThemeProvider>
    )
        ;
}

export default IndexPage
